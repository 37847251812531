<template>
  <v-dialog v-model="showDialog" max-width="650px" @input="v => v || closeDialog()">
    <v-card>
      <v-card-title class="headline">{{ formTitle }}</v-card-title>
      <v-form v-model="valid" lazy-validation ref="badgeForm">
      <v-alert v-if="!valid" type="warning" tile>There is a validation error. Please check all fields and re-try.</v-alert>
      <v-expansion-panels accordion tile class="mb-4" v-model="initialPanel">  
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-info-circle</v-icon>
            Badge {{editedItem.name}}
            <v-spacer />
            <v-icon v-if="!valid && !editedItem.name" small color="warning">fa-exclamation-triangle</v-icon>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field v-model="editedItem.name" label="Badge name" required :rules="nameRules"></v-text-field>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-window-restore</v-icon>
            Popup Window
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Customize the badge popup window which can be used to provide additional information on this badge.</p>
            <v-textarea v-model="editedItem.description" rows="3" label="Description (optional)" ></v-textarea>
            <v-select
              :items="siteData.badge_details_types"
              v-model="editedItem.details_type" 
              item-value="type"
              item-text="text"
              label="Type of popup"
              hide-details
              solo
              full-width
              class="mt-0 mb-4"
            ></v-select>
            <p v-if="tenant.isDefault" class="text-muted">
              Please read our <a href="https://corporatefitness.helpscoutdocs.com/article/86-badge-details-popups" target="_blank"><i class="fadl fa fa-books"/> Knowledge Base article</a> on how to configure badge details to achieve best results.
            </p>

            <v-text-field 
              v-if="editedItem.details_type == 'STREET_VIEW'" 
              v-model="editedItem.details_pano_id" 
              label="Google Street View Panorama ID" 
              persistent-hint
              hint="Leave empty to show the panorama as it would be on the location of this badge on the virtual course map."
              />
            <v-select 
              v-if="editedItem.details_type == 'VIDEO' && adminVideos.length"
              :items="adminVideos" 
              item-text="msg" 
              item-value="id" 
              v-model="editedItem.apivideo_id" 
              label="Attach a video"
              />                        
            <v-text-field 
              v-if="editedItem.details_type == 'VIDEO' && !adminVideos.length" 
              v-model="editedItem.details_video" 
              label="Youtube video link (or mp4 video) url" 
              persistent-hint
              hint="Enter the Youtube url (e.g. https://www.youtube.com/watch?v=TTAU7lLDZYU) or a link to a mp4 video."
              />
            <p v-if="!editedItem.id && (editedItem.details_type == 'IMAGE' || editedItem.details_type == 'PANORAMA')" class="text-muted">
              Please save your badge first before you can upload a picture here.
            </p>
            <v-row v-if="editedItem.id && (editedItem.details_type == 'IMAGE' || editedItem.details_type == 'PANORAMA')" >
              <v-col cols="6">
                <h5>Upload Image</h5>
                <v-file-input filled prepend-icon="fa fa-camera" accept="image/*" :rules="uploadRules" label="Select image to show in popup window" v-model="uploadFile" v-on:change="uploadBadgeDetailsImage"></v-file-input>
                <p v-if="editedItem.details_type == 'PANORAMA'" class="text-muted mb-0">Image must be in equirectangular projection.</p>
              </v-col>
              <v-col v-if="editedItem.details_img" cols="6">
                <h5>Preview</h5>
                <img :src="editedItem.details_img" style="max-width:100%; max-height:200px;" />
                <p v-if="editedItem.details_type == 'PANORAMA'" class="text-muted mb-0">It is normal for panoramas to show distorted in the preview.</p>
              </v-col>
            </v-row>
<!--                       <v-text-field 
              v-if="editedItem.details_type == 'IMAGE'" 
              v-model="editedItem.details_img" 
              label="Url to image to show in popup window" 
              />
            <v-text-field 
              v-if="editedItem.details_type == 'PANORAMA'" 
              v-model="editedItem.details_img" 
              label="Url to image to show in popup window" 
              persistent-hint
              hint="This should be an image in equirectangular projection"
              />
-->
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-tools</v-icon>
            Configuration
            <v-spacer/>
            <span v-if="editedItem.active" class="shrink mr-4 green--text">ACTIVE</span>
            <span v-if="!editedItem.active" class="shrink mr-4 red--text">INACTIVE</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
          <v-row>
            <v-col cols="4">
              <v-switch v-model="editedItem.active" class="mt-0" label="Active" hint="" persistent-hint></v-switch>
              <v-switch v-if="race.collective" v-model="editedItem.collective" class="mt-0" label="Based on Collective Progress" hint="" persistent-hint></v-switch>
              <v-switch v-model="editedItem.show_map" class="mt-0" label="Show on Map" hint="" persistent-hint></v-switch>
              <v-switch v-model="editedItem.post_feed" class="mt-0" label="Post to Feed" hint="" persistent-hint></v-switch>
              <v-switch v-model="editedItem.hide_until_rewarded" class="mt-0" label="Hide until rewarded" hint="When enabled, this badge won't be shown on leaderboard page" persistent-hint></v-switch>
            </v-col>
            <v-col cols="8" v-if="editedItem.active">
              <p class="text-muted">Optional date restriction. Only set when different from event/leaderboard dates:</p>
              <v-row no-gutters>
                <v-col cols="6">
                  <DateWithTimeZonePicker v-model="editedItem.from" :timeZone="event.timeZoneOlson" clearable label="Active from (included)" :minDate="race.from || event.from" :maxDate="race.till || event.till" class="pt-0 mt-0" />
                </v-col>
                <v-col cols="6">
                  <DateWithTimeZonePicker v-model="editedItem.till" :timeZone="event.timeZoneOlson" clearable label="Active till (included)" :minDate="race.from || event.from" :maxDate="race.till || event.till" class="pt-0 mt-0" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="editedItem.metric == 'COORDINATE' && race.scoring == 'TRACK' && betaMode" cols="12" sm="12" md="12" class="__py-0">
              <v-text-field
                v-model="editedItem.audio_msg" 
                label="Audio Message" 
                hint="Enter optional audio message to play when getting close to this location"
                prepend-icon="fa-volume"
                persistent-hint
                />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="race.scoring == 'BADGES'" cols="6" class="py-0">
              <v-switch v-model="editedItem.include_challenge" class="mt-0" label="Include in Challenge Goal" hide-details></v-switch>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-select
                :items="otherBadgesInRace"
                v-model="editedItem.after_badge_id" 
                item-value="id"
                item-text="name"
                label="Only After Badge"
                hint="Use for multi-sport or ordered sequence of badges"
                persistent-hint
                class="mt-0"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0 pt-4">
              <h4>Bonus Score</h4>
              <p>Give bonus points when this badge is awarded.</p>
              <DistanceTextArea
                v-if="race.scoring == 'TIME'" 
                v-model.number="computedBonusValue" 
                :unit="event.unit"
                :mode="$helpers.UnitType.NUMBER"
                :multiplier="3600"
                label="Bonus (in hours)" 
                />
              <DistanceTextArea
                v-else-if="race.scoring == 'DISTANCE'" 
                v-model.number="computedBonusValue" 
                :unit="event.unit"
                :mode="$helpers.UnitType.DISTANCE"
                :label="`Bonus (in ${event.unit == 'METRIC' ? 'km' : 'mi'})`" 
                />
              <v-text-field 
                v-else
                v-model.number="computedBonusValue" 
                label="Bonus score" 
                :hint="unitTypeDiplay"
                persistent-hint
                class="mt-0"
              ></v-text-field>
            </v-col>
          </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-bullseye</v-icon>
            Goal
            <v-spacer/>
            <span class="shrink mr-4">{{ (siteData.badge_metrics.find(x => x.type == editedItem.metric) || {}).text }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Select the metric on which this badge should be based. Please read <a href="https://corporatefitness.helpscoutdocs.com/article/84-badges" target="_blank">the documentation</a> for tips and tricks.</p>
            <v-select
              :items="siteData.badge_metrics"
              v-model="editedItem.metric" 
              item-value="type"
              item-text="text"
              label="Metric"
              :rules="selectRules"
              hide-details
              solo
              full-width
              class="mt-0 mb-4"
            ></v-select>
            <h3 v-if="showMetricUnit || showValue">Goal Configuration</h3>
            <v-row>
            <v-col v-if="showMetricUnit" cols="12" sm="4" md="4" class="py-0">
              <v-select
                :items="siteData.badge_units"
                v-model="editedItem.unit" 
                item-value="type"
                item-text="text"
                label="Unit type"
                :rules="selectRules"
                class="mt-0 "
              ></v-select>
            </v-col>
            <v-col v-if="showValue" cols="12" sm="4" md="4" class="py-0">
              <DistanceTextArea
                v-if="editedItem.metric == 'DURATION' || (editedItem.metric == 'SCORE' && race.scoring == 'TIME')" 
                v-model.number="computedValue" 
                :unit="event.unit"
                :mode="$helpers.UnitType.NUMBER"
                :multiplier="3600"
                label="Total duration (in hours)" 
                >
              </DistanceTextArea>
              <v-text-field 
                v-else
                v-model.number="computedValue" 
                :label="valueLabel" 
                required 
                class="mt-0"
              ></v-text-field>
            </v-col>
            <v-col v-if="editedItem.metric == 'ACTIVITY' || editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS'" cols="12" class="py-0">
              <h4>Activity Filter</h4>
            </v-col>
            <v-col v-if="editedItem.metric == 'BEST_ACTIVITY' || editedItem.metric == 'ACTIVITY' || editedItem.metric == 'RACE_RESULT' || editedItem.metric == 'AGGREGATION'" cols="12" sm="12" md="12" class="py-0">
              <v-select
                :items="siteData.badge_fields"
                v-model="editedItem.activity_field" 
                item-value="type"
                item-text="text"
                label="Field"
                class="mt-0"
              ></v-select>
            </v-col>
            <v-col v-if="editedItem.metric == 'AGGREGATION'" cols="12" sm="12" md="12" class="py-0">
              <v-select
                :items="siteData.scoring_aggregation_types"
                v-model="editedItem.activity_aggr" 
                item-value="type"
                item-text="text"
              label="Aggregation Method"
                class="mt-0"
              ></v-select>
              <v-text-field
                v-if="editedItem.activity_aggr == 'FORMULA'"
                v-model="editedItem.transformation_expr" 
                label="Custom Formula" 
                persistent-hint
                hint="Advanced setting. Enter a custom transformation function."
                class="mt-0"
                />
            </v-col>
            <v-col v-if="editedItem.metric == 'ACTIVITY' || editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS'" cols="12" sm="3" md="3" class="py-0">
              <v-select
                :items="activitiesWithDefault"
                v-model="editedItem.activity_type" 
                item-value="type"
                item-text="text"
                label="Activity Type"
                class="mt-0"
              ></v-select>
            </v-col>
            <v-col v-if="(editedItem.metric == 'BEST_ACTIVITY' || editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS') && editedItem.activity_type != 'DAILY_SUMMARY'" cols="12" sm="3" md="3" class="py-0">
              <DistanceTextArea
                v-model="editedItem.activity_distance" 
                :unit="event.unit"
                :label="`Min distance (in ${unitTypeDiplay})`" 
                >
              </DistanceTextArea>
            </v-col>
            <v-col v-if="editedItem.metric == 'ACTIVITY' && editedItem.activity_type != 'DAILY_SUMMARY'" cols="12" sm="3" md="3" class="py-0">
              <DistanceTextArea
                v-model="editedItem.activity_distance_min" 
                :unit="event.unit"
                :label="`Min distance (in ${unitTypeDiplay})`" 
                >
              </DistanceTextArea>
            </v-col>
            <v-col v-if="editedItem.metric == 'ACTIVITY' && editedItem.activity_type != 'DAILY_SUMMARY'" cols="12" sm="3" md="3" class="py-0">
              <DistanceTextArea
                v-model="editedItem.activity_distance_max" 
                :unit="event.unit"
                :label="`Max distance (in ${unitTypeDiplay})`" 
                >
              </DistanceTextArea>
            </v-col>
            <v-col v-if="editedItem.metric == 'ACTIVITY' && editedItem.activity_type == 'DAILY_SUMMARY'" cols="12" sm="3" md="3" class="py-0">
              <DistanceTextArea
                v-model="editedItem.activity_steps_min" 
                :mode="$helpers.UnitType.NUMBER"
                :label="`Min steps (optional)`" 
                />
            </v-col>
            <v-col v-if="editedItem.metric == 'ACTIVITY' && editedItem.activity_time_min && editedItem.activity_type != 'DAILY_SUMMARY'" cols="12" sm="6" md="6" class="py-0">
              <DurationTextArea
                v-model="editedItem.activity_time_min" 
                dense
                label="Min duration (optional)" 
                >
              </DurationTextArea>
            </v-col>
            <v-col v-else-if="(editedItem.metric == 'ACTIVITY' || editedItem.metric == 'BEST_ACTIVITY' || editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS') && editedItem.activity_type != 'DAILY_SUMMARY'" cols="12" sm="6" md="6" class="py-0">
              <DurationTextArea
                v-model="editedItem.activity_time" 
                dense
                :label="editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS' || editedItem.metric == 'BEST_ACTIVITY' || !editedItem.activity_distance ? `Min duration (optional)` : `Max duration (optional)`" 
                >
              </DurationTextArea>
            </v-col>
            <v-col v-if="editedItem.metric == 'ACTIVITY' && editedItem.activity_type != 'DAILY_SUMMARY'" cols="12" sm="6" md="6" class="py-0">
              <DurationTextArea
                v-model="editedItem.activity_time_max" 
                dense
                label="Max duration (optional)" 
                >
              </DurationTextArea>
            </v-col>
            <v-col v-if="editedItem.metric == 'ACTIVITY' && editedItem.activity_type != 'DAILY_SUMMARY'" cols="12" sm="6" md="6" class="py-0">
              <v-select
                :items="providersWithDefault"
                v-model="editedItem.activity_prov" 
                item-value="type"
                item-text="text"
                label="Activity provider (data source)"
                class="mt-0"
              ></v-select>
            </v-col>
            <v-col v-if="editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS'" cols="12" sm="6" md="6" class="py-0">
              <DistanceTextArea
                v-model="editedItem.activity_steps" 
                :mode="$helpers.UnitType.NUMBER"
                label="Min # Steps" 
                />
            </v-col>
            <v-col v-if="race.custom && (editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS')" cols="12" sm="6" md="6" class="py-0">
              <DistanceTextArea
                v-model="editedItem.activity_custom_min" 
                :mode="$helpers.UnitType.NUMBER"
                :label="`Min # ${race.custom}`" 
                />
            </v-col>
            <v-col v-if="editedItem.metric == 'COORDINATE'" cols="12" class="py-0">
              <h3>Coordinate</h3>
            </v-col>
            <v-col v-if="editedItem.metric == 'COORDINATE'" cols="12" sm="3" md="3" class="py-0">
              <DistanceTextArea
                v-model="editedItem.coord_lat" 
                :mode="$helpers.UnitType.NUMBER"
                label="Latitude" 
                >
              </DistanceTextArea>
            </v-col>
            <v-col v-if="editedItem.metric == 'COORDINATE'" cols="12" sm="3" md="3" class="py-0">
              <DistanceTextArea
                v-model="editedItem.coord_lng" 
                :mode="$helpers.UnitType.NUMBER"
                label="Longitude" 
                >
              </DistanceTextArea>
            </v-col>
            <template v-if="editedItem.metric == 'RACE_RESULT'">
            <v-col cols="12" class="py-0">
              <h3>Race Result</h3>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-0">
              <v-select
                v-if="seriesEvents && seriesEvents.length"
                :items="$helpers.prefixSelectListWith(seriesEvents, event)"
                v-model="editedItem.linked_event_id" 
                item-value="id"
                item-text="name"
                label="Event (from series)" 
                />
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-0">
              <v-select
                :items="otherRacesInEvent"
                v-model="editedItem.linked_race_id" 
                item-value="id"
                item-text="name"
                label="Race" 
                />
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-0">
              <v-switch
                v-model="editedItem.incomplete_result" 
                label="Allow incomplete results" 
                persistent-hint
                hint="When enabled, also partial results will show. Otherwise only completed results will show."
                />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="editedItem.transformation_expr" 
                label="Transformation Expression" 
                persistent-hint
                hint="Advanced setting. Enter a custom transformation function."
                />
            </v-col>
            </template>
            <v-col v-if="editedItem.metric == 'COORDINATE'" cols="12" sm="6" md="6" class="py-0">
              Use <a href="https://www.latlong.net/" target="_blank">this webpage</a> to quickly find the coordinate of any location.
            </v-col>

            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-envelope</v-icon>
            Email
            <v-spacer/>
            <span v-if="editedItem.send_email" class="shrink mr-4 green--text">ON</span>
            <span v-if="!editedItem.send_email" class="shrink mr-4 red--text">OFF</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
              <v-alert type="info" outlined>All email customization options will soon move to the new <router-link :to="{name:'eventmanagerEmails', params: {id:event.id}}">Email Customization</router-link> screen.</v-alert>
              <p>Enable the email option to have an automatic email send when this badge is earned. The email contains a Share option which helps to increase engagement of this challenge.</p>
              <v-btn v-if="editedItem.send_email && editedItem.id" outlined color="secondary" class="float-right" @click="sendTestEmail">Send test email</v-btn>
              <v-switch v-model="editedItem.send_email" class="mt-0" label="Send email" hint="Enable to send a (customizable) email whenever this badge is earned with social share options." persistent-hint></v-switch>
              <MarkdownEditor v-if="editedItem.send_email" v-model="editedItem.email_template" :eventId="event.id" label="Badge reward email" />
          </v-expansion-panel-content>
        </v-expansion-panel>
<!--                   <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fa fa-info-circle</v-icon>
            General
            <v-spacer/>
            <v-switch label="test" hide-details class="ma-0 mr-4"/>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </v-expansion-panel-content>
        </v-expansion-panel>
-->                </v-expansion-panels>                
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="closeDialog">Cancel</v-btn>
        <v-btn color="secondary"  :loading="$store.getters.isLoading" @click="saveDialog">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
    
</template>
<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import MarkdownEditor from "@/components/MarkdownEditor";
import QrCode from '@/components/generic/QrCode.vue'
import Header from './_Header.vue'
import RaceSelector from './_RaceSelector.vue'
import DurationTextArea from "@/components/DurationTextArea";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Badges",
  components: {
    DateWithTimeZonePicker,
    DistanceTextArea,
    DurationTextArea,
    MarkdownEditor,
    Header,
    RaceSelector,
    QrCode,
  },
  props: {
    event: null,
    seriesEvents: null,
    race: null,
    badges: null,
  },
  data() {
    return {
      tenant: tenant,
      showDialog: false,
      initialPanel: 0,
      valid: false,
      editedIndex: -1,
      editedItem: {active:true, color:'#F46524', metric: 'SCORE'},
      defaultItem: {active:true, color:'#F46524', metric: 'SCORE'},
      siteData: siteData,
      adminVideos: [],
      adminVideoId: null,
      nameRules: [
        v => !!v || "Please enter the name",
      ],
      selectRules: [
        v => !!v || "Please select an item",
      ],
    };
  },
  async mounted() {
    
  },
  
  methods: {
    async openDialog(item) {
      this.editedItem.linked_event_id = this.event.id;
      this.defaultItem.linked_event_id = this.event.id;

      this.editedIndex = this.badges.indexOf(item);
      this.editedItem = Object.assign({}, item || this.defaultItem);
      this.showDialog = true;

      if (this.editedItem.meta && this.editedItem.meta.some(x => x.key == 'apivideo.id')) {
        this.editedItem.apivideo_id = this.editedItem.meta.find(x => x.key == 'apivideo.id').val_str;
      }

      if (!this.adminVideos.length) {
        this.adminVideos = (await eventManagerService.getVideos(this.event.id, true)).data.data ?? [];
      }
    },

    async uploadBadgeImage() {
      if (this.uploadFile) {
        let formData = new FormData()
        formData.append("file", this.uploadFile, this.uploadFile.name);
        const response = await eventManagerService.uploadBadgeImage(this.event.id, this.race.id, this.editedItem.id, formData);
        if (response.data.status == "OK") {
          this.editedItem.custom_img = response.data.msg;
          this.updatePreviewImage();
        }
        this.$helpers.toastResponse(this, response.data, 'Image is uploaded successful!');
      }
    },

    async uploadBadgeDetailsImage() {
      if (this.uploadFile) {
        let formData = new FormData()
        formData.append("file", this.uploadFile, this.uploadFile.name);
        const response = await eventManagerService.uploadBadgeDetailsImage(this.event.id, this.race.id, this.editedItem.id, formData);
        this.$helpers.toastResponse(this, response.data, 'Image is uploaded successful!');
        if (response.data.status == "OK") {
          this.editedItem.details_img = response.data.msg;
        }
      }
    },

    closeDialog () {
      this.showAddAchievementDialog = false
      this.showDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.previewUrl = null;
      })
    },

    async saveDialog() {
      this.$refs.badgeForm.validate();
      this.$nextTick(async () => {
        if (this.valid) {
          var success = await this.saveBadge();
          if (success) this.closeDialog();
        }
        else {
          console.log('Invalid form state', this.$refs.badgeForm.errorMessages);
        }
      });
    },
    async saveBadge() {
      console.log('Saving', this.editedItem);
      var response = await eventManagerService.putBadge(this.event.id, this.race.id, this.editedItem);
      if (response.data.status == 'OK') {
        this.$helpers.toastResponse(this, response.data, 'Badge saved successfully.')
        this.$emit('saved');
        return true;
      }
      return false;
    },

    badgeUnitTypeDiplay(badgeUnitType) {
      return badgeUnitType == 'PERCENTAGE' ? '%' : this.unitTypeDiplay;
    },

  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    readonly() {
      return this.race && this.race.team && this.race.badge_scoring =='MANUAL_CLAIM';
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    otherRacesInEvent(){
      if (this.editedItem && this.editedItem.linked_event_id != null && this.editedItem.linked_event_id != this.event.id && this.seriesEvents) {
        return this.seriesEvents.find(x => x.id == this.editedItem.linked_event_id).races;
      }
      return this.event.races.filter(x => x.id != this.race.id);
    },
    otherBadgesInRace(){
      return [{id: null, name: '- none -'}, ...this.badges.filter(x => x.id != this.editedItem.id)];
    },
    activitiesWithDefault(){
      var data = siteData.activities;
      data.unshift({type: null, text: '- Any -'});
      return data;
    },
    providersWithDefault(){
      var data = siteData.providers;
      data.unshift({type: null, text: '- Any -'});
      return data;
    },
    unitTypeDiplay() {
      if (this.race.scoring == 'CUSTOM') {
        return this.race.custom || 'Points';
      }
      if (this.race.scoring == 'STEPS') {
        return 'Steps';
      }
      return this.event == null || this.event.unit == 'METRIC' ? 'km' : 'mi';
    },
    showValue() {
      if (this.editedItem.metric == 'MANUAL_CLAIM' && this.race && this.race.scoring == 'BADGES') {
        return true;
      }
      if (this.editedItem && ['ACTIVITY', 'BEST_ACTIVITY', 'AGGREGATION', 'MANUAL_CLAIM', 'RACE_RESULT', 'PHOTO', 'STORY'].includes(this.editedItem.metric)) {
        return false;
      }
      return true;
    },
    showMetricUnit() {
      return this.editedItem && ['DISTANCE', 'ELEVATION', 'ELEVATION_LOSS', 'SCORE'].includes(this.editedItem.metric);
    },
    valueLabel() {
      if (!this.editedItem) {
        return null;
      }
      if (this.editedItem.metric == 'MANUAL_CLAIM') {
        return 'Points';
      }
      if (this.editedItem.metric == 'STORY') {
        return 'Story length (in characters)';
      }
      if (this.editedItem.metric == 'CONSECUTIVE_DAYS') {
        return 'Day(s)';
      }
      if (this.editedItem.metric == 'CONSECUTIVE_WEEKS') {
        return 'Week(s)';
      }
      if (this.editedItem.metric == 'DURATION') {
        return 'Hours';
      }
      if (this.editedItem.metric == 'COORDINATE') {
        return `Radius (in meter)`;
      }
      if (this.editedItem && this.editedItem.unit == 'FIXED') {
        if (this.editedItem.metric == 'DISTANCE' || (this.editedItem.metric == 'SCORE' && this.race.scoring == 'DISTANCE'))
          return `Distance (in ${this.unitTypeDiplay})`;
        else if (this.editedItem.metric == 'ELEVATION' || this.editedItem.metric == 'ELEVATION_LOSS' || (this.editedItem.metric == 'SCORE' && this.race.scoring == 'ELEVATION'))
          return `Elevation (in ${this.unitTypeDiplay})`;
        return `${this.unitTypeDiplay}`;
      }
      if (this.editedItem && this.editedItem.unit == 'PERCENTAGE') {
        return 'Percentage (0 - 100)';
      }
    },
    computedValue:{
      get () {
        return this.editedItem && this.editedItem.value ? this.editedItem.value : 0
      },
      set (value) {
        this.editedItem.value = value || 0 // coerce to null
      }
    },
    computedBonusValue:{
      get () {
        return this.editedItem && this.editedItem.bonus ? this.editedItem.bonus : 0
      },
      set (value) {
        this.editedItem.bonus = value || 0 // coerce to null
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Badge' : 'Edit Badge'
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

